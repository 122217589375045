import React from "react"
import Helmet from 'react-helmet'

const LeadPlusPage = () => (
  <>
    <Helmet title="Property Report">
      <script defer src="https://dpr.leadplus.com.au/main.js"></script>
    </Helmet>
    <div id="daintegration"></div>
  </>
)

export default LeadPlusPage
